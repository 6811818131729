import React, { Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core css
import 'primeicons/primeicons.css' //icons
import ProtectedRoutes from './auth/ProtectedRoutes'



const loading = (
  <div
      className="pt-3 d-flex justify-content-center align-items-center bg-theme-light"
      style={{ height: "100vh" }}
    >
      <div>
        <i
          className="pi pi-spin pi-spinner text-theme-dark"
          style={{ fontSize: "5em" }}
        ></i>
      </div>
    </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const VerifyAccountPage = React.lazy(()=> import('./views/pages/verify-account/VerifyAccount'))
const ForgotPasswordPage = React.lazy(() => import('./views/pages/forgot-password/Forgot_password'))
const ResetPasswordPage = React.lazy(() => import('./views/pages/reset-password/ResetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  // const currentPath = window.location.pathname;
  // console.log("route=>", currentPath);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Login" element={<Login />} />
          <Route exact path="/register" name="Register" element={<Register />} />
          <Route exact path="/verify-account/:token" name="Verify Account" element={<VerifyAccountPage />} />
          <Route exact path="/forgot-password" name="Forgot Password" element={<ForgotPasswordPage />} />
          <Route exact path="/reset-password/:token" name="Reset Password" element={<ResetPasswordPage />} />
          {/* <Route exact  path="*" name="Page 404" element={<Page404 />} /> */}
          {/* <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
          <Route exact path="/" element={<ProtectedRoutes />}>
            <Route exact path="*" name="dashboard" element={<DefaultLayout />} />
          </Route>
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
